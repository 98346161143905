@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch');
@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css?family=Lexend+Deca');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  display: flex;
  background: "#ff8038" !important;
}

#root {
  min-height: 100vh;
  width: 100%;
  flex-shrink: 0;
}

.MuiSvgIcon-root {
  color: "primary.text"
}

.MuiTab-root {
  text-transform: "none";
  background-color: "primary.background";
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}
