body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.price-models {
  display: inline;
}

.price-models th {
  min-width: 200px;
  text-align: left;
}

.price-models td {
  min-width: 200px;
  text-align: left;
}

.glow-shadow {
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 26px 0px rgba(134, 85, 207, 0.9);
  -moz-box-shadow: 0px 0px 26px 0px rgba(134, 85, 207, 0.9);
  box-shadow: 0px 0px 26px 0px rgba(134, 85, 207, 0.9);
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.flipper {
  animation: flip 2s infinite;
  /* Use the flip animation for 3 seconds and repeat infinitely */
  transform-style: preserve-3d;
  position: relative;
}


@keyframes flip {

  0%,
  100% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }
}

@keyframes flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.flow {
  animation: flow 8s ease-in-out infinite;
}

.scaling-pulse {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.ag-theme-balham {
  --ag-header-background-color: #f2f3fc !important;
}

.ag-theme-minimal {
  /* disable all borders */
  --ag-borders: none !important;
  /* then add back a border between rows */
  --ag-row-border-style: none !important;
  /* --ag-header-foreground-color: white; */
  --ag-header-background-color: none !important;
  --ag-header-cell-hover-background-color: none !important;
  --ag-header-cell-moving-background-color: none !important;
  --ag-cell-moving-background-color: none !important;
  --ag-cell-moving-background-color: none !important;
  /* --ag-row-border-width: 5px; */
  /* --ag-row-border-color: rgb(150, 150, 200); */
}

.ag-theme-balham .ag-row-hover {
  background-color: #f2f3fc !important;
}

.ag-theme-balham .ag-row-hover:hover::before {
  background-color: #f2f3fc !important;
}